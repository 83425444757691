import Cookies from 'js-cookie'

const tk = 'tk'
const RToken = "R-Token"
const RefreshToken = 'R-Token'

export function gettk() {
  return Cookies.get(tk)
}

export function setRToken(token){
	return Cookies.set(RToken, token)
}

export function settk(token) {
  return Cookies.set(tk, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRefresh() {
  return Cookies.get(RefreshToken)
}

export function setRefresh(token) {
  return Cookies.set(RefreshToken, token)
}

export function removeRefresh() {
  return Cookies.remove(RefreshToken)
}