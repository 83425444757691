import { createRouter, createWebHistory } from 'vue-router'
import layout from "@/views/layout/index"

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/index')
  },  
  {
    path: '/datamanage',
    name: 'dataManage',
    component: layout,
    children: [{
        path: "",
        redirect: "weather"
      },
      {
        path: "weather",
        component: () =>
          import ('@/views/data-manage/weatherData')
      },
      {
        path: "load/heat",
        component: () =>
          import ('@/views/data-manage/load/heatLoad'),
      },
      {
        path: "load/cooling",
        component: () =>
          import ('@/views/data-manage/load/coolingLoad'),
      },
      {
        path: "load/electricity",
        component: () =>
          import ('@/views/data-manage/load/electricityLoad'),
      },
      {
        path: "load/steam",
        component: () =>
          import ('@/views/data-manage/load/steamLoad'),
      },
      //////////
      {
        path: "energy/electricity",
        component: () =>
          import ('@/views/data-manage/energy/electricityEnergy')
      },
      {
        path: "energy/fuel",
        component: () =>
          import ('@/views/data-manage/energy/fuelEnergy')
      },
      {
        path: "energy/cooling",
        component: () =>
          import ('@/views/data-manage/energy/coolingEnergy')
      },
      {
        path: "energy/heat",
        component: () =>
          import ('@/views/data-manage/energy/heatEnergy')
      },
    ]
  },
  {
    path: "/",
    name: 'mapTopo',
    component: layout,
    children: [{
        path: "",
        redirect: "/mytopo"
      },
      {
        path: '/mytopo',
        name: "myTopo",
        component: () =>
          import ("@/views/map-topo/MyTopo")
      },
      {
        path: "/topocanvas",
        name: "topoCanvas",
        component: () => import ('@/views/map-topo/TopoCanvas'),
      }
    ]
  },
  {
    path: "/simulation",
    name: "simulation",
    component: layout,
    children: [{
        path: "setparameter",
        component: () =>
          import ('@/views/simulation/SetParameter')
      },
      {
        path: "result",
        component: () =>
          import ('@/views/simulation/result')
      },
      {
        path: "pfmap",
        component: () =>
          import ('@/views/simulation/PfMap')
      }
    ]
  },
  {
    path: "/optimization",
    name: "optimization",
    component: layout,
    children: [
			{
				path: "",
				component: () => import ('@/views/optimization/index')
			},
			{
        path: "pfmap",
        component: () => import ('@/views/optimization/PfMap')
      }
		]
  },
	// {
  //   path: "/administrator",
  //   name: "administrator",
  //   component: layout,
  //   children: [{
  //     path: "",
  //     component: () =>
  //       import ('@/views/administrator/index')
  //   }]
  // },
  {
    path: "/carbonNeutral",
    name: "carbonNeutral",
    component: layout,
    children: [{
        path: "",
        component: () => import('@/views/CarbonNeutral/index')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // 匹配所有不存在的路径
    name: 'NotFound',
    component: () =>
      import ('@/views/error-page/not-found.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
