import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import "@/styles/index.css"
import "@/styles/iconfont.css"

import Keycloak from "keycloak-js"
import { settk } from '@/hooks/cookie.js'

const app = createApp(App);
app
	.use(store)
	.use(router)
	.use(ElementPlus)
	.mount('#app')


app.mixin({
	methods: {
		closeBeforeSave() {
			//如果进入页面不进行点击等操作，直接关闭，会不触发
			window.onbeforeunload = function (e) {
				e = e || window.event; // 兼容IE8和Firefox 4之前的版本
				if (e) {
					e.returnValue = "关闭提示";
				}
				// Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
				return "关闭提示";
			};
		},
		isObjectValueEqual(a, b) { // a, b 对象的值是否相等
			//取对象a和b的属性名
			var aProps = Object.getOwnPropertyNames(a);
			var bProps = Object.getOwnPropertyNames(b);
			//判断属性名的length是否一致
			if (aProps.length != bProps.length) {
				return false;
			}
			//循环取出属性名，再判断属性值是否一致
			for (var i = 0; i < aProps.length; i++) {
				var propName = aProps[i];
				if (a[propName] !== b[propName]) {
					return false;
				}
			}
			return true;
		}
	}
});



Date.prototype.Format = function (fmt) {
	let o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (let k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}
