const mutations = {
	changeShowCreateTemplate(state, data) {
		state.topo.flags.showCreateTemplate = data;
	},
	changeShowTopoCanvas(state, data) {
		state.topo.flags.showTopoCanvas = data;
	},
	changeShowCustomCellBox(state, data) {
		state.topo.flags.showCustomCellBox = data
	},

	reTopoData(state, data) {
		state.topo.comData.topoData = data
	},
	saveRecentData(state, data) {
		state.topo.comData.recentData = data
	},
	

	/////////////////////////////////////
	saveLoginData(state, data) {
		state.user.username = data.username;
		state.user.id = data.uid;
	},

	///////////////////////////////////////
	changeDataManageFlag(state, data) {
		state.dataManage.flag.showDataDetails = data
	},
	sendDataManageData(state, data) {
		state.dataManage.data = data
	},
	sendLoadRateTakeValue(state, data) {
		state.dataManage.loadRateTake = data
	},
	sendRenderLoadRate(state, data) {
		state.dataManage.renderLoadRate = data
	},

	refreshDataList(state) {
		state.dataManage.refreshDataListFlag++
	},

	saveEXTData(state, data){
		state.extData = data
	},

	changeShowSettings(state, data) {
		state.topo.flags.showSettings = data;
	},
	saveLoadData(state, data){
		state.loadData[data.type] = data.data
	},


	getElectricityLoadDataOptions(state, data){
		state.shapeAttr[20].cellAttr.data.baseData.multipleValue[0].labelOptions = data
	},

	registerDone(state, data){
		state.isRegisterDone = data
	},

	savePfData(state, data) {
		state.pfmapData = data
	}
}

export default mutations;