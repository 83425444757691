<template>
<div class="logo" style="height: 80px;"> </div>

<el-row class="tac">
  <el-col :span="24">
    <el-menu
      router
      class="el-menu-vertical-demo"
      :uniqueOpened="true"
      :default-active="$route.path"
      @open="handleOpen"
      @close="handleClose"
      background-color="#02142C"
      text-color="#fff"
      active-text-color="#ffffff">
      <!-- <el-menu-item index="/">
        <i class="el-icon-location"></i>
        <template #title>首页</template>
      </el-menu-item> -->

      <el-submenu index="/datamanage">
        <template #title><i class="el-icon-document"></i>数据管理</template>
        <el-menu-item index="/datamanage/weather">气象数据</el-menu-item>
        <el-submenu index="/datamanage/load/electricity">
          <template #title>负载数据</template>
          <el-menu-item index="/datamanage/load/heat">采暖负荷</el-menu-item>
          <el-menu-item index="/datamanage/load/cooling">制冷负荷</el-menu-item>
          <el-menu-item index="/datamanage/load/electricity">电负荷</el-menu-item>
          <el-menu-item index="/datamanage/load/steam">蒸汽负荷</el-menu-item>
        </el-submenu>
        <el-submenu index="/datamanage/energy/electricity">
          <template #title>能源数据</template>
          <el-menu-item index="/datamanage/energy/electricity">电力</el-menu-item>
          <el-menu-item index="/datamanage/energy/fuel">燃料</el-menu-item>
          <el-menu-item index="/datamanage/energy/cooling">冷</el-menu-item>
          <el-menu-item index="/datamanage/energy/heat">热</el-menu-item>
        </el-submenu>
      </el-submenu>

      <el-submenu index="/">
        <template #title><i class="el-icon-menu"></i>拓扑建模</template>
        <el-menu-item index="/mytopo">模型管理</el-menu-item>
        <el-menu-item index="/topocanvas">建模绘制</el-menu-item>
      </el-submenu>

      <el-submenu index="/simulation">
        <template #title><i class="el-icon-menu"></i>模拟运行</template>
        <el-menu-item index="/simulation/setparameter">参数设置</el-menu-item>
        <el-menu-item index="/simulation/result">运行结果</el-menu-item>
      </el-submenu>

      <el-menu-item index="/optimization">
        <i class="el-icon-menu"></i>
        <template #title>运行优化</template>
      </el-menu-item>

      <el-menu-item index="/carbonNeutral">
        <i class="el-icon-document"></i>
        <template #title>碳中和优化</template>
      </el-menu-item>

			<!-- <el-menu-item index="/administrator">
        <i class="el-icon-document"></i>
        <template #title>管理员</template>
      </el-menu-item> -->

    </el-menu>
  </el-col>
</el-row>
</template>

<script>
"use strict";
export default {
  name: "sliderBar",

  setup(){
    function handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    };
    function handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }

    return {
      handleOpen,
      handleClose
    }
  }
}
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1280px) {
  .el-submenu .el-menu-item{
    width: 175px !important;
  }
}
.logo{
	background: url("../../../assets/logo.png") no-repeat center center;
}

a:visited{
  color: white;
}

/* 下拉菜单一个像素的突出 */
.el-menu{
	overflow-x: hidden;
  border-right-width: 0;

  .el-menu-item{
    user-select: none;
    
  }
}


.tac {
  :deep(.el-submenu__title){
    user-select: none;
  }

	.el-menu-item.is-active {
		background-color: #189ed9 !important;//你要修改的颜色
	}

	overflow: hidden !important;

}

</style>

<style>
.el-menu{
	overflow-x: hidden;
}
</style>