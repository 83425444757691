<template>
  <router-view />
</template>

<script type="text/javascript">
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "app",

  setup(){
    const store = useStore()
    const router = useRouter()

    if (store.state.user.hasOwnProperty("token")) {
      //
    } else {
      router.push("/")
    }
  }
}
</script>
