<template>
<div class="layout">
  <el-container>
    <el-aside width="200px">
      <sliderBar></sliderBar>
    </el-aside>
    <el-container>
      <div class="headerBar" v-if="$route.path != '/topocanvas'"
        :style="{ boxShadow: '0 2px 5px #ccc' }"
      >
        <span>
          {{topoData.templateName || ""}}
        </span>
        <div class="userContorl">
          <!-- <div class="userIcon" :style="{ border: '1px solid #ccc' }" >
            <img src="https://huyaimg.msstatic.com/avatar/1098/bc/379e2ec563fe2de4078b0607f6fca8_180_135.jpg?1553767703?454233" alt="">
          </div> -->
          <span class="userName">{{username}}</span>
          <div class="contorler">
            <span @click="logoutAccount">注销</span>
          </div>
        </div>
      </div>
      <el-main :style="{ marginTop: marginTopFlag ? '45px' : 0 }">
          <router-view></router-view>
      </el-main>
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </el-container>
</div>
</template>

<script>
import sliderBar from "./componetns/SliderBar.vue";
import { ElMessage } from 'element-plus';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "vue";

export default {
  name: "layout",
  components: { sliderBar },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
		// const instance = getCurrentInstance();
		// let keycloak = instance?.appContext.config.globalProperties.$keycloak

    let topoData = computed(() => { return store.state.topo.comData.topoData });
    let username = computed(() => { return store.state.user.username });
    let marginTopFlag = ref(true)

    let colorArea = "0123456789ABCDEF";

    let num = ref(1);
    setInterval(() => {
      num.value++
    }, 1000);

    let coo = ref("");
    let bor = ref("");
    watch(num, val => {
      let hex = "#";
      for (let c = 0; c < 6; c++) {
        let index = Math.floor(Math.random() * 16);
        hex += colorArea[index];
      }
      coo.value = "0 2px 5px" + hex;
      bor.value = "1px solid" + hex;
    })


    watch(() => { return route.path }, val => {
      marginTopFlag.value = val == "/topocanvas" ? false : true;
    })

    function logoutAccount() {
      logout()
        .then(res => {
          if (res.data.code === 20000) {
            ElMessage({ type: "success", message: "退出账户成功" })
            router.push("/")
          }
        })
        .catch(err => { console.log(err); })
    }

    return {
      logoutAccount,
      topoData,
      username,
      marginTopFlag,

      coo,
      bor
    }
  }
}
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
}

.el-container {
  width: 100%;
  height: 100%;
  // margin-bottom: 40px;

  .headerBar{
    // -moz-box-shadow:0px 2px 5px #ccc;
    // -webkit-box-shadow:0px 2px 5px #ccc;
    // box-shadow:0px 2px 5px #ccc;
    color: #333;
    height: 45px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    padding: 0 20px;
    z-index: 99;
    width: calc(100% - 200px);
    background-color: white;

    .userContorl{
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover .contorler{
        display: block;
      }
      &:hover .userName{
        color: #315efb;
      }

      .userIcon{
        border-radius: 50%;
        // width: 30px;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          vertical-align: baseline;
        }
      }
      .userName{
        margin-left: 10px;
        line-height: 30px;
        font-size: 14px;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: top;
      }
      .contorler{
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
        background-color: #333;
        width: 84px;
        padding: 8px 0;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
        -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
        -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
        -o-box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
        border-radius: 12px;
        border: 1px solid #d7d9e0;
        overflow: hidden;
        font-size: 12px;
        line-height: 18px;
        text-align: center;

        span{
          &:hover{
            color: #315efb;
          }
        }
      }
    }
    
  }
  .el-aside {
    background-color: #02142C;
    color: #333;
    text-align: left;
  }
  .el-main {
    background-color: #F0F2F5;
    color: #333;
    padding: 0;
    /* display: flex; */
    position: relative;
		overflow: hidden;
  }
}
</style>
