// #region
// 电网
import externalPowerImg from "../../assets/shapeImg/externalPower.png";
import transformerImg from "../../assets/shapeImg/transformer.png";
import lineImg from "../../assets/shapeImg/line.png";
import capacitorImg from "../../assets/shapeImg/capacitor.png";
import electricBusesImg from "../../assets/shapeImg/electricBuses.png";
// 发电
import fanImg from "../../assets/shapeImg/fan.png";
import photovoltaicImg from "../../assets/shapeImg/photovoltaic.png";
import gasTurbineImg from "../../assets/shapeImg/gasTurbine.png";
import gasEngineImg from "../../assets/shapeImg/gasEngine.png";
import steamTurbineImg from "../../assets/shapeImg/steamTurbine.png";
// 热网
import pipeImg from "../../assets/shapeImg/pipe.png";
import pipeColdImg from "../../assets/shapeImg/pipeCold.png";
import pipeHotImg from "../../assets/shapeImg/pipeHot.png";
import centrifugalPumpImg from "../../assets/shapeImg/centrifugalPump.png";
import hydrauNodeHImg from "../../assets/shapeImg/hydrauNodeH.png";
import hydrauNodeCImg from "../../assets/shapeImg/hydrauNodeC.png";
// 制冷制热
import gasBoilerImg from "../../assets/shapeImg/gasBoiler.png";
import solarCollectorsImg from "../../assets/shapeImg/solarCollectors.png";
import elecBoilerImg from "../../assets/shapeImg/elecBoiler.png";
import compressionChillerImg from "../../assets/shapeImg/compressionChiller.png";
import absorptionChillerImg from "../../assets/shapeImg/absorptionChiller.png";
// 负荷
import electricLoadImg from "../../assets/shapeImg/electricLoad.png";
import coldLoadImg from "../../assets/shapeImg/coldLoad.png";
import heatLoadImg from "../../assets/shapeImg/heatLoad.png";
import load_hydrogenImg from "../../assets/shapeImg/load_hydrogen.png";
// 储能
import powerStorageImg from "../../assets/shapeImg/powerStorage.png";
import electrolysisImg from "../../assets/shapeImg/electrolysis.png";
import hydrogenCellBatteryImg from "../../assets/shapeImg/hydrogenCellBattery.png";
import waterTankCoolImg from "../../assets/shapeImg/waterTankCool.png";
import waterTankHeatImg from "../../assets/shapeImg/waterTankHeat.png";
import hydrogenStorageImg from "../../assets/shapeImg/hydrogenStorage.png";
// 热泵
import heatPumpHCImg from "../../assets/shapeImg/heatPumpHC.png";
import store from "..";
// #endregion



const shapeAttr = [
	// todo 电网
	// #region
	{
		type: "powerGrid",
		enName: "PowerSupply",
		url: externalPowerImg,
		cellAttr: {
			data: {
				shapeName: "externalPower",
				isModifiable: false,
				name: "外部电源",
				realName: "Power_Supply",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "外部电源",
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '85%',
					y: 15
				},
			}]
		}
	},
	{
		type: "powerGrid",
		enName: "Transformer",
		url: transformerImg,
		cellAttr: {
			data: {
				shapeName: "transformer",
				isModifiable: false,
				name: "变压器",
				realName: "Transformer",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "变压器",
					}, {
						category: "额定容量(MVA)",
						shorthand: "Transformer_Cap",
						value: 0,
					}, {
						category: "原边额定电压有效值(kV)",
						shorthand: "Transformer_vn_hv",
						value: 0,
					}, {
						category: "副边额定电压有效值(kV)",
						shorthand: "Transformer_vn_lv",
						value: 0,
					}, {
						category: "短路电阻(p.u.)",
						shorthand: "Transformer_kr_trafo",
						value: 0,
					}, {
						category: "励磁电导(p.u.)",
						shorthand: "Transformer_x_trafo",
						value: 0,
					}, {
						category: "励磁电纳(p.u.)",
						shorthand: "Transformer_c_trafo",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '78%',
					y: 7
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '22%',
					y: 7
				},
			}]
		}
	},
	{
		type: "powerGrid",
		enName: "Line",
		url: lineImg,
		cellAttr: {
			data: {
				shapeName: "line",
				isModifiable: false,
				name: "线路",
				realName: "line",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "线路",
					}, {
						category: "额定电压(kV)",
						shorthand: "line_Vn",
						value: 0,
					}, {
						category: "长度(m)",
						shorthand: "line_length",
						value: 0,
					}],
					multipleValue: [
						{
							category: "线缆类型",
							shorthand: "line_type",
							value: "",
							labelOptions: [
								{
									label: "LGJ-240 架空线",
									value: "LGJ-240"
								},
								{
									label: "LGJ-150 架空线",
									value: "LGJ-150"
								},
								{
									label: "LGJ-70 架空线",
									value: "LGJ-70"
								},
								{
									label: "YJV22-300 电缆",
									value: "YJV22-300"
								},
								{
									label: "YJV22-240 电缆",
									value: "YJV22-240"
								},
								{
									label: "YJV22-185 电缆",
									value: "YJV22-185"
								}
							]
						}, {
							category: "单位长度电阻(p.u./km)",
							shorthand: "R_ohm_spec",
							value: 0,
						}, {
							category: "单位长度电抗(p.u./km)",
							shorthand: "X_ohm_spec",
							value: 0
						}
					]
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '97%',
					y: 40
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '3%',
					y: 40
				},
			}]
		}
	},
	{
		type: "powerGrid",
		enName: "Capacitor",
		url: capacitorImg,
		cellAttr: {
			data: {
				shapeName: "capacitor",
				isModifiable: false,
				name: "电容器",
				realName: "Capacitor",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "电容器",
					}, {
						category: "额定电压(V)",
						shorthand: "Capacitor_Vn",
						value: 0,
					}, {
						category: "额定容量(MVA)",
						shorthand: "Capacitor_Cap",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeInPort",
				args: {
					x: '49%',
					y: 5
				},
			}]
		}
	},
	{
		type: "powerGrid",
		enName: "ElectricBuses",
		url: electricBusesImg,
		cellAttr: {
			data: {
				shapeName: "electricBuses",
				isModifiable: false,
				name: "母线",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "母线",
					}, {
						category: "额定电压(kV)",
						shorthand: "line_Vn",
						value: "",
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeAllPort",
				args: {
					x: '43%',
					y: 39
				},
			}]
		}
	},
	//#endregion
	// todo 发电
	// #region
	{
		type: "powerGeneration",
		enName: "WindTurbine",
		url: fanImg,
		cellAttr: {
			data: {
				shapeName: "fan",
				isModifiable: false,
				name: "风机",
				realName: "Wind_turbine",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "风机",
					}, {
						category: "切入风速(m/s)",
						shorthand: "Wind_speed_in",
						value: 0,
					}, {
						category: "切出风速(m/s)",
						shorthand: "Wind_speed_out",
						value: 0,
					}, {
						category: "额定风速(m/s)",
						shorthand: "Wind_speed_std",
						value: 0,
					}, {
						category: "风机额定功率",
						shorthand: "Wind_turbine_Pmax",
						value: 0,
					}, {
						category: "风机功率因数",
						shorthand: "Wind_turbine_PF",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '50%',
					y: 77
				},
			}]
		}
	},
	{
		type: "powerGeneration",
		enName: "Pv",
		url: photovoltaicImg,
		cellAttr: {
			data: {
				shapeName: "photovoltaic",
				isModifiable: false,
				name: "光伏",
				realName: "PV",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "光伏",
					}, {
						category: "面积(m²)",
						shorthand: "area",
						value: 0,
					}, {
						category: "光伏额定功率(kW)",
						shorthand: "PV_Pmax",
						value: 0,
					},{
						category: "光伏功率因数",
						shorthand: "PV_PF",
						value: 0,
					},{
						category: "光电转换效率",
						shorthand: "PV_coeff",
						value: 0,
					}, {
						category: "光伏电池温度系数",
						shorthand: "PV_coeff_T",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '50%',
					y: 75
				},
			}]
		}
	},
	{
		type: "powerGeneration",
		enName: "GasTurbine",
		url: gasTurbineImg,
		cellAttr: {
			data: {
				shapeName: "gasTurbine",
				isModifiable: true,
				name: "燃气轮机",
				realName: "Gas_turbine",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "燃气轮机",
					}, {
						category: "额定电功率(kW)",
						shorthand: "Gas_turbine_Pmax",
						value: 0,
					}],
					multipleValue: [
						{
							category: "供电效率曲线系数类型",
							shorthand: "coeffE",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供电效率曲线系数a",
							shorthand: "coeffEa",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数b",
							shorthand: "coeffEb",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数c",
							shorthand: "coeffEc",
							disabled: false,
							value: 0,
						}, {
							category: "供热效率曲线系数类型",
							shorthand: "coeffH",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供电效率曲线系数a",
							shorthand: "coeffHa",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数b",
							shorthand: "coeffHb",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数c",
							shorthand: "coeffHc",
							disabled: false,
							value: 0,
						}
					],
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '98%',
					y: 40
				},
			}, {
				group: "orangeOutPort",
				args: {
					x: '35%',
					y: 26
				},
			}]
		}
	},
	{
		type: "powerGeneration",
		enName: "GasEngine",
		url: gasEngineImg,
		cellAttr: {
			data: {
				shapeName: "gasEngine",
				isModifiable: true,
				name: "燃气内燃机",
				realName: "Gas_engine",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "燃气内燃机",
					}, {
						category: "额定电功率(kW)",
						shorthand: "Gas_engine_Pmax",
						value: 0,
					}, {
						category: "额定热功率(kW)",
						shorthand: "Gas_engine_Hmax",
						value: 0,
					}],
					multipleValue: [
						{
							category: "供电效率曲线系数类型",
							shorthand: "coeffE",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供电效率曲线系数a",
							shorthand: "coeffEa",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数b",
							shorthand: "coeffEb",
							disabled: false,
							value: 0,
						}, {
							category: "供电效率曲线系数c",
							shorthand: "coeffEc",
							disabled: false,
							value: 0,
						}, 
						{
							category: "供热效率曲线系数类型",
							shorthand: "coeffH",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供热效率曲线系数a",
							shorthand: "coeffHa",
							disabled: false,
							value: 0,
						}, {
							category: "供热效率曲线系数b",
							shorthand: "coeffHb",
							disabled: false,
							value: 0,
						}, {
							category: "供热效率曲线系数c",
							shorthand: "coeffHc",
							disabled: false,
							value: 0,
						}
					]
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '85%',
					y: 15
				},
			}, {
				group: "orangeOutPort",
				args: {
					x: '50%',
					y: 20
				},
			}]
		}
	},
	{
		type: "powerGeneration",
		enName: "SteamTurbine",
		url: steamTurbineImg,
		cellAttr: {
			data: {
				shapeName: "steamTurbine",
				isModifiable: true,
				name: "蒸汽轮机",
				realName: "Steam_turbine",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "蒸汽轮机",
					}, {
						category: "额定蒸汽流量(m3/h)",
						shorthand: "steamV",
						value: 0,
					}, {
						category: "额定蒸汽进口温度(℃)",
						shorthand: "steamStdT",
						value: 0,
					},{
						category: "蒸汽轮机额定电功率(kW)",
						shorthand: "Steam_turbine_Pmax",
						value: 0,
					}, {
						category: "发电效率",
						shorthand: "Steam_turbine_ele_efficiency",
						value: 0,
					}, {
						category: "供热效率",
						shorthand: "Steam_turbine_heat_efficiency",
						value: 0,
					}, {
						category: "蒸汽轮机功率因数",
						category: "功率因数",
						shorthand: "Steam_turbine_PF",
						value: 0,
					}],
					multipleValue: []
				}
		},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '40%',
					y: 75
				},
			}, {
				group: "redOutPort",
				args: {
					x: '88%',
					y: 75
				},
			}]
		}
	},
	// #endregion
	// todo 热网
	// #region
	{
		type: "hotNet",
		enName: "PipeHot",
		url: pipeHotImg,
		cellAttr: {
			data: {
				shapeName: "pipeCold",
				isModifiable: false,
				name: "热管道",
				realName: "Pipe",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "热管道",
					}, {
						category: "管道内径(mm)",
						shorthand: "Pipe_D",
						value: 0,
					}, {
						category: "管道粗糙度(mm)",
						shorthand: "Pipe_Ra",
						value: 0,
					}, {
						category: "管道传热系数(W/(m·K))",
						shorthand: "Pipe_coeffHeat",
						value: 0,
					}, {
						category: "长度(m)",
						shorthand: "Pipe_length",
						value: 0
					}, {
						category: "介质流速(m/s)",
						shorthand: "Pipe_V",
						value: 0
					}, {
						category: "介质密度(kg/m^3)",
						shorthand: "Pipe_density",
						value: 0
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '98%',
					y: 40
				},
			}, {
				group: "redInPort",
				args: {
					x: '2%',
					y: 40
				},
			}]
		}
	},
	{
		type: "hotNet",
		enName: "PipeCold",
		url: pipeColdImg,
		cellAttr: {
			data: {
				shapeName: "pipeHot",
				isModifiable: false,
				name: "冷管道",
				realName: "Pipe",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "冷管道",
					}, {
						category: "管道内径(mm)",
						shorthand: "Pipe_D",
						value: 0,
					}, {
						category: "管道粗糙度(mm)",
						shorthand: "Pipe_Ra",
						value: 0,
					}, {
						category: "管道传热系数(W/(m·K))",
						shorthand: "Pipe_coeffHeat",
						value: 0,
					}, {
						category: "长度(m)",
						shorthand: "Pipe_length",
						value: 0
					}, {
						category: "介质流速(m/s)",
						shorthand: "Pipe_V",
						value: 0
					}, {
						category: "介质密度(kg/m^3)",
						shorthand: "Pipe_density",
						value: 0
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "midBlueOutPort",
				args: {
					x: '98%',
					y: 40
				},
			}, {
				group: "midBlueInPort",
				args: {
					x: '2%',
					y: 40
				},
			}]
		}
	},
	{
		type: "hotNet",
		enName: "CentrifugalPump",
		url: centrifugalPumpImg,
		cellAttr: {
			data: {
				shapeName: "centrifugalPump",
				isModifiable: false,
				name: "离心泵",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "离心泵",
					}, {
						category: "泵效率",
						shorthand: "coeff",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '33%',
					y: 12
				},
			}, {
				group: "redInPort",
				args: {
					x: '5%',
					y: 47
				},
			}]
		}
	},
	{
		type: "hotNet",
		enName: "HydrauNodeH",
		url: hydrauNodeHImg,
		cellAttr: {
			data: {
				shapeName: "hydrauNodeH",
				isModifiable: false,
				name: "热网节点",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "热网节点",
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redAllPort",
				args: {
					x: '50%',
					y: 30
				},
			}]
		}
	},
	{
		type: "hotNet",
		enName: "HydrauNodeC",
		url: hydrauNodeCImg,
		cellAttr: {
			data: {
				shapeName: "hydrauNodeC",
				isModifiable: false,
				name: "冷网节点",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "冷网节点",
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "midBlueAllPort",
				args: {
					x: '50%',
					y: 30
				},
			}]
		}
	},
	// #endregion
	// todo 制冷制热
	// #region 
	{
		type: "coolingAndHeating",
		enName: "GasBoiler",
		url: gasBoilerImg,
		cellAttr: {
			data: {
				shapeName: "gasBoiler",
				isModifiable: true,
				name: "燃气锅炉",
				realName: "Gas_boiler",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "燃气锅炉",
					}, {
						category: "额定功率(kW)",
						shorthand: "Gas_boiler_Hmax",
						value: 0,
					}, {
						category: "制热效率",
						shorthand: "Gas_boiler_heat_efficiency",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '79%',
					y: 0
				},
			}]
		}
	},
	{
		type: "coolingAndHeating",
		enName: "SolarCollector",
		url: solarCollectorsImg,
		cellAttr: {
			data: {
				shapeName: "solarCollectors",
				isModifiable: false,
				name: "太阳能集热器",
				realName: "Solar_collector",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "太阳能集热器",
					}, {
						category: "额定功率(kW)",
						shorthand: "Solar_collector_Hmax",
						value: 0,
					}, {
						category: "制热效率",
						shorthand: "Solar_collector_heat_efficiency",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '70%',
					y: 10
				},
			}]
		}
	},
	{
		type: "coolingAndHeating",
		enName: "EleBoiler",
		url: elecBoilerImg,
		cellAttr: {
			data: {
				shapeName: "elecBoiler",
				isModifiable: true,
				name: "电极锅炉",
				realName: "Ele_boiler",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "电极锅炉",
					}, {
						category: "额定功率(kW)",
						shorthand: "Ele_boiler_Hmax",
						value: 0,
					}, {
						category: "制热效率",
						shorthand: "Ele_boiler_heat_efficiency",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeInPort",
				args: {
					x: '48%',
					y: 1
				},
			}, {
				group: "redOutPort",
				args: {
					x: '73%',
					y: 1
				},
			}]
		}
	},
	{
		type: "coolingAndHeating",
		enName: "EleAc",
		url: compressionChillerImg,
		cellAttr: {
			data: {
				shapeName: "compressionChiller",
				isModifiable: true,
				name: "压缩式制冷机",
				realName: "Ele_AC",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "压缩式制冷机",
					}, {
						category: "额定制冷量(kW)",
						shorthand: "Ele_AC_Cmax",
						value: 0,
					}, {
						category: "COP",
						shorthand: "Ele_AC_COP",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "midBlueOutPort",
				args: {
					x: '90%',
					y: 53
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '30%',
					y: 20
				},
			}]
		}
	},
	{
		type: "coolingAndHeating",
		enName: "AbsorptionAc",
		url: absorptionChillerImg,
		cellAttr: {
			data: {
				shapeName: "absorptionChiller",
				isModifiable: true,
				name: "吸收式制冷机",
				realName: "Absorption_AC",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "吸收式制冷机",
					}, {
						category: "额定电功率(kW)",
						shorthand: "Absorption_AC_Cmax",
						value: 0,
					}, {
						category: "制冷效率",
						shorthand: "Absorption_AC_COP",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "midBlueOutPort",
				args: {
					x: '90%',
					y: 30
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '35%',
					y: 16
				},
			}, {
				group: "redInPort",
				args: {
					x: '5%',
					y: 40
				},
			}]
		}
	},
	//#endregion
	// todo 负荷
	// #region
	{
		type: "load",
		enName: "ElectricLoad",
		url: electricLoadImg,
		cellAttr: {
			data: {
				shapeName: "electricLoad",
				isModifiable: false,
				name: "电负荷",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "电负荷",
					}, {
						category: "面积m²",
						shorthand: "Area",
						value: 0,
					}],
					multipleValue: [
						{
							category: "电负荷模型",
							shorthand: "",
							value: "",
							labelOptions: [],
							lid: null
						}
					]
				}
			},
			items: [{
				group: "orangeInPort",
				args: {
					x: '88%',
					y: 70
				},
			}]
		}
	},
	{
		type: "load",
		enName: "ColdLoad",
		url: coldLoadImg,
		cellAttr: {
			data: {
				shapeName: "coldLoad",
				isModifiable: false,
				name: "冷负荷",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "冷负荷",
					}, {
						category: "面积m²",
						shorthand: "Area",
						value: 0,
					}],
					multipleValue:[
						{
							category: "冷负荷模型",
							shorthand: "",
							value: "",
							labelOptions: [],
							lid: null
						}
					]
				}
			},
			items: [{
				group: "midBlueInPort",
				args: {
					x: '88%',
					y: 70
				},
			}]
		}
	},
	{
		type: "load",
		enName: "HeatLoad",
		url: heatLoadImg,
		cellAttr: {
			data: {
				shapeName: "heatLoad",
				isModifiable: false,
				name: "热负荷",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "热负荷",
					}, {
						category: "面积m²",
						shorthand: "Area",
						value: 0,
					}],
					multipleValue: [
						{
							category: "热负荷模型",
							shorthand: "",
							value: "",
							labelOptions: [],
							lid: null
						}
					]
				}
			},
			items: [{
				group: "redInPort",
				args: {
					x: '88%',
					y: 70
				},
			}]
		}
	},
	{
		type: "load",
		enName: "LoadHydrogen",
		url: load_hydrogenImg,
		cellAttr: {
			data: {
				shapeName: "load_hydrogen",
				isModifiable: false,
				name: "气负荷",
				realName: "",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "气负荷",
					}, {
						category: "面积m²",
						shorthand: "Area",
						value: 0,
					}],
					multipleValue: [
						{
							category: "蒸汽负荷模型",
							shorthand: "",
							value: "",
							labelOptions: [],
							lid: null
						}
					]
				}
			},
			items: [{
				group: "lightBlueInDottedPort",
				args: {
					x: '9%',
					y: 40
				},
			}]
		}
	},
	// #endregion
	// todo 储能
	// #region
	{
		type: "energyStorage",
		enName: "EleStorage",
		url: powerStorageImg,
		cellAttr: {
			data: {
				shapeName: "powerStorage",
				isModifiable: true,
				name: "储电",
				realName: "Ele_storage",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "储电",
					}, {
						category: "额定储电电量(kWh)",
						shorthand: "Ele_storage_Cap",
						value: 0,
					}, {
						category: "额定充电功率(kW)",
						shorthand: "Ele_storage_charge_Pmax",
						value: 0,
					}, {
						category: "额定放电功率(kW)",
						shorthand: "Ele_storage_discharge_Pmax",
						value: 0,
					}, {
						category: "充电效率",
						shorthand: "Ele_storage_charge_efficiency",
						value: 0,
					}, {
						category: "放电效率",
						shorthand: "Ele_storage_discharge_efficiency",
						value: 0,
					}, {
						category: "自损耗率",
						shorthand: "Ele_storage_loss",
						value: 0,
					}, {
						category: "初始状态",
						shorthand: "Ele_storage_SOC_in",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeInPort",
				args: {
					x: '72%',
					y: 10
				},
			}]
		}
	},
	{
		type: "energyStorage",
		enName: "P2G",
		url: electrolysisImg,
		cellAttr: {
			data: {
				shapeName: "electrolysis",
				isModifiable: true,
				name: "P2G设备",
				realName: "P2G",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "P2G设备",
					}, {
						category: "最大用电功率(kW)",
						shorthand: "P2G_Pmax",
						value: 0,
					}, {
						category: "产气效率",
						shorthand: "P2G_efficiency",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeInPort",
				args: {
					x: '50%',
					y: 0
				},
			}, {
				group: "lightBlueOutDottedPort",
				args: {
					x: '50%',
					y: 80
				},
			}]
		}
	},
	{
		type: "energyStorage",
		enName: "FuelCell",
		url: hydrogenCellBatteryImg,
		cellAttr: {
			data: {
				shapeName: "hydrogenCellBattery",
				isModifiable: true,
				name: "燃料电池",
				realName: "Fuel_cell",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "燃料电池",
					}, {
						category: "最大输出功率(kW)",
						shorthand: "Fuel_cell_Pmax",
						value: 0,
					}, {
						category: "发电效率",
						shorthand: "Fuel_cell_efficiency",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "orangeOutPort",
				args: {
					x: '85%',
					y: 15
				},
			}, {
				group: "lightBlueInDottedPort",
				args: {
					x: '15%',
					y: 15
				},
			}]
		}
	},
	{
		type: "energyStorage",
		enName: "CoolStorage",
		url: waterTankCoolImg,
		cellAttr: {
			data: {
				shapeName: "waterTankCool",
				isModifiable: true,
				name: "储冷",
				realName: "Cool_storage",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "储冷",
					}, {
						category: "额定储冷量(kWh)",
						shorthand: "Cool_storage_Cap",
						value: 0,
					}, {
						category: "额定充冷功率(kW)",
						shorthand: "Cool_storage_charge_Cmax",
						value: 0,
					}, {
						category: "额定放冷功率(kW)",
						shorthand: "Cool_storage_discharge_Cmax",
						value: 0,
					}, {
						category: "充冷效率",
						shorthand: "Cool_storage_charge_efficiency",
						value: 0,
					}, {
						category: "放冷效率",
						shorthand: "Cool_storage_discharge_efficiency",
						value: 0,
					}, {
						category: "自损耗率",
						shorthand: "Cool_storage_loss",
						value: 0,
					}, {
						category: "初始状态",
						shorthand: "Cool_storage_SOC_in",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "midBlueInPort",
				args: {
					x: '93%',
					y: 30
				},
			}]
		}
	},
	{
		type: "energyStorage",
		enName: "HeatStorage",
		url: waterTankHeatImg,
		cellAttr: {
			data: {
				shapeName: "waterTankHeat",
				isModifiable: true,
				name: "储热",
				realName: "Heat_storage",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "储热",
					}, {
						category: "额定储热量(kWh)",
						shorthand: "Heat_storage_Cap",
						value: 0,
					}, {
						category: "额定充热功率(kW)",
						shorthand: "Heat_storage_charge_Hmax",
						value: 0,
					}, {
						category: "额定放热功率(kW)",
						shorthand: "Heat_storage_discharge_Hmax",
						value: 0,
					}, {
						category: "充热效率",
						shorthand: "Heat_storage_charge_efficiency",
						value: 0,
					}, {
						category: "放热效率",
						shorthand: "Heat_storage_discharge_efficiency",
						value: 0,
					}, {
						category: "自损耗率",
						shorthand: "Heat_storage_loss",
						value: 0,
					}, {
						category: "初始状态",
						shorthand: "Heat_storage_SOC_in",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "redInPort",
				args: {
					x: '50%',
					y: 3
				},
			}]
		}
	},
	{
		type: "energyStorage",
		enName: "AirTank",
		url: hydrogenStorageImg,
		cellAttr: {
			data: {
				shapeName: "hydrogenStorage",
				isModifiable: true,
				name: "储气",
				realName: "Air_tank",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "储气",
					}, {
						category: "额定储气量(kWh)",
						shorthand: "Air_tank_Cap",
						value: 0,
					}, {
						category: "额定充气速率(kW)",
						shorthand: "Air_tank_in_rate",
						value: 0,
					}, {
						category: "额定供气速率(kW)",
						shorthand: "Air_tank_out_rate",
						value: 0,
					}],
					multipleValue: []
				}
			},
			items: [{
				group: "lightBlueInDottedPort",
				args: {
					x: '50%',
					y: 3
				},
			}]
		}
	},
	// #endregion
	// todo 热泵
	// #region
	{
		type: "heatPump",
		enName: "Hp",
		url: heatPumpHCImg,
		cellAttr: {
			data: {
				shapeName: "heatPumpHC",
				isModifiable: true,
				name: "冷热双供热泵",
				realName: "HP",
				hasLngLat: false,
				baseData: {
					radioValue: [
						{
							category: "元件描述",
							shorthand: "cellDesc",
							value: "冷热双供热泵",
						}, {
							category: "额定电功率(kW)",
							shorthand: "HP_Pmax",
							value: 0,
						}, {
							category: "额定制热功率(kW)",
							shorthand: "HP_Hmax",
							value: 0,
						}, {
							category: "额定制冷功率(kW)",
							shorthand: "HP_Cmax",
							value: 0,
						}
					],
					multipleValue: [
						{
							category: "供热COP系数类型",
							shorthand: "COPH",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供热COP系数a",
							shorthand: "COPHa",
							disabled: false,
							value: 0,
						}, {
							category: "供热COP系数b",
							shorthand: "COPHb",
							disabled: false,
							value: 0,
						}, {
							category: "供热COP系数c",
							shorthand: "COPHc",
							disabled: false,
							value: 0,
						}, {
							category: "供冷COP系数类型",
							shorthand: "COPC",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						}, {
							category: "供冷COP系数a",
							shorthand: "COPCa",
							disabled: false,
							value: 0,
						}, {
							category: "供冷COP系数b",
							shorthand: "COPCb",
							disabled: false,
							value: 0,
						}, {
							category: "供冷COP系数c",
							shorthand: "COPCc",
							disabled: false,
							value: 0,
						}
					]
				},
			},
			items: [{
				group: "midBlueOutPort",
				args: {
					x: '3%',
					y: 60
				},
			}, {
				group: "redOutPort",
				args: {
					x: '97%',
					y: 60
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '49%',
					y: 10
				},
			}]
		}
	},
	{
		type: "heatPump",
		enName: "HeatPumpHc",
		url: heatPumpHCImg,
		cellAttr: {
			data: {
				shapeName: "heatPumpHC",
				isModifiable: true,
				name: "供热热泵",
				realName: "HP",
				hasLngLat: false,
				baseData: {
					radioValue: [{
						category: "元件描述",
						shorthand: "cellDesc",
						value: "供热热泵",
					}, {
						category: "额定电功率(kW)",
						shorthand: "HP_Pmax",
						value: 0,
					}, {
						category: "额定热功率(kW)",
						shorthand: "HP_Hmax",
						value: 0,
					}],
					multipleValue: [
						{
							category: "供热COP系数类型",
							shorthand: "COPH",
							value: "",
							labelOptions: [
								{
									label: "常数型",
									value: "常数型"
								},
								{
									label: "一次型",
									value: "一次型"
								},
								{
									label: "二次型",
									value: "二次型"
								}
							]
						},
						{
							category: "供热COP系数a",
							shorthand: "COPHa",
							disabled: false,
							value: 0,
						}, {
							category: "供热COP系数b",
							shorthand: "COPHb",
							disabled: false,
							value: 0,
						}, {
							category: "供热COP系数c",
							shorthand: "COPHc",
							disabled: false,
							value: 0,
						}
					]
				}
			},
			items: [{
				group: "redOutPort",
				args: {
					x: '97%',
					y: 60
				},
			}, {
				group: "orangeInPort",
				args: {
					x: '50%',
					y: 10
				},
			}]
		}
	},
	// #endregion
]


export default shapeAttr;