// import topo from "./modules/topo.js";
import shapeAttr from "./modules/shapeAttr.js";
import cityCode from "@/store/cityCode.json";

const state = {
	// topo,
	shapeAttr,
	allAd: cityCode,
	loadData: {},
	user: {},
	extData: {},

	topo: {
		flags: {
			showCreateTemplate: false,
			showTopoCanvas: false,
			showCustomCellBox: false,
			showSettings: false
		},
		comData: {
			topoData: {
				// area: "",
				// json: [],
				// templateName: ""
			},
			recentData: {
				cells: [],
				templateName: ""
			}
		}
	},
	dataManage: {
		refreshDataListFlag: 1,


		data: {},
		rate: {},
		flag: {
			showDataDetails: false
		},
		loadRateTake: [],
		renderLoadRate: []
	},
	isRegisterDone: false,
	pfmapData: {}
}

export default state;